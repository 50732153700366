import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchImages = createAsyncThunk('todos/fetchTodos', async (folder) => {
  const response = await axios.get(
    'https://fortunetreehomes.com/api/images/'+folder
  );
  return response;
});

export const fetchLookImages = createAsyncThunk('todos/fetchLooks', async ({folder,Bookname}) => {
  const response = await axios.get(
    'https://fortunetreehomes.com/api/lookbook/'+folder+'/'+Bookname 
  );
  return response;
});


export const imageSlice = createSlice({
  name: 'images',
  initialState: {
    imageList: [],
    layoutImagesData: null,
    content:{},
    heroImage:null,
    lookbook:null,
    constructData:null,
    status: 'idle',
    error: null,
    loading:false
  },
  reducers: {

  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchImages.fulfilled, (state, action) => {
      // Add user to the state array
      state.imageList=action.payload.data.imageData;
      state.layoutImagesData=action.payload.data.layoutImagesData;
      state.content=action.payload.data.content
      state.heroImage= action.payload.data.heroImage
      state.lookbook=action.payload.data.designData
      state.constructData=action.payload.data.constructData
      state.loading=false;
      
    })
    builder.addCase(fetchImages.pending, (state, action) => {
      // Add user to the state array
      state.imageList=[];
      state.layoutImagesData=null;
      state.content={};
      state.heroImage=null;
      state.lookbook=null;
      state.loading=true;
      state.constructData=null;
      
    })
    
  },
});



export default imageSlice.reducer;