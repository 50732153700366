import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Navbar } from './Navbar';
import { Suspense, lazy } from 'react';

// Lazy load the pages
const Homepage = lazy(() => import('./pages/homepage.jsx'));
const Ourhomes = lazy(() => import('./pages/ourhomes.jsx'));
const OurLots = lazy(() => import('./pages/ourLots'));
const PropertyDetails = lazy(() => import('./pages/PropertyDetails'));
const OurConstructions=lazy(()=>import('./pages/ourConstructions.jsx'))

const ContactUs = lazy(() => import('./pages/ContactUs'));
const PDFViewer = lazy(() => import('./components/PDFviewer'));

function App() {
  return (
    <Router> 
      <MainContent />
    </Router>
  );
}

// Component to render main content, including Navbar and Routes
function MainContent() {
  const location = useLocation();
  // Determine whether to show the Navbar based on the current route
  const showNavbar = !location.pathname.includes('/pdf-viewer');

  return (
    <>
      {showNavbar && <Navbar />}
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {/* Route for the landing page */}
          <Route exact path="/" element={<Homepage />} />
          <Route exact path="/OurHomes" element={<Ourhomes />} />
          <Route exact path="/OurLots" element={<OurLots />} />
          <Route exact path="/property/:id" element={<PropertyDetails />} />
          <Route exact path="/ContactUs" element={<ContactUs />} />
          <Route exact path='/OurConstructions' element={<OurConstructions/>} />
        
          <Route exact path="/pdf-viewer" element={<PDFViewer />} />
         
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
