import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchThumbs = createAsyncThunk('Thumbs/fetchThumbs', async (folder) => {
  const response = await axios.get(
    'https://fortunetreehomes.com/api/'+folder
  );
  return response;
});

//https://fortunetreehomes.com/api/




export const imageSlice = createSlice({
  name: 'Portifolio',
  initialState: {
    portifolio: [],
    status: 'idle',
    error: null,
  },
  reducers: {

  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchThumbs.fulfilled, (state, action) => {
      // Add user to the state array
      state.portifolio=action.payload.data;
      
      
    })
    builder.addCase(fetchThumbs.pending, (state, action) => {
      // Add user to the state array
      state.portifolio=[];
     
      
    })
    
  },
});



export default imageSlice.reducer;