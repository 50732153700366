
import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CloseIcon from '@mui/icons-material/Close';
import './navbar.css';

export const Navbar = () => {

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [isSmall, setIsSmall] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        // Set isSmall to true when scrolling down, otherwise set it to false
        setIsSmall(window.scrollY > window.innerHeight / 2);
      };
  
      window.addEventListener('scroll', handleScroll);
  
      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

  const toggleDrawer  = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(!drawerOpen);
  };
  return (
    <div className={`NavBox ${isSmall ? 'small' : ''}`}>
      <Link to={`/`}>
        
        <img  className='NavItems' src="/favicon.ico" alt="Italian Trulli" style={{borderRadius:"40%"}} />
        </Link>
        
       <div className='NavItems'>
        
        <button  onClick={toggleDrawer}>
        {drawerOpen ? (
                        <CloseIcon sx={{ fontSize: '2.5rem' }} />
                    ) : (
                        <MenuRoundedIcon sx={{ fontSize: '2.5rem' }} />
                    )}
        </button>
        {drawerOpen && (
                    <div className="menuLinks" onClick={toggleDrawer}>
                        <Link to="/">Home</Link>
                        <Link to="/OurHomes">Portfolio</Link>
                        <Link to="/OurConstructions">Available Homes</Link>
                        <Link to="/OurLots">Available Lots</Link> 
                        <Link to="/ContactUs">Contact US</Link>
                        
                    </div>
                )}


        </div>


       
            
  
  </div>
  )
}
